import React from "react"
import Booking from "../components/Booking/booking"
import Layout from "../components/Layout/layout"

const BookingPage = (
  {
    pageContext
  }
) => {


  const {
    footer, navBar,
    booking
  } = pageContext
  return (
    <div className="getstarted-page">
      <Layout footer={footer} navBar={navBar}>
        <Booking data={booking} />
      </Layout>
    </div>
  )
}

export default BookingPage
