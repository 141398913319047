import React from "react"
import "../GetStarted/getstarted.css"

const Booking = ({
  data
}) => (
  <div className="getstarted-content">
    <div className="container">
      <div className="getstarted-wrapper">
        <div className="getstarted-head">
          <div dangerouslySetInnerHTML={{ __html: data?.title }}>
            {/* <h2>Book your call with us!</h2>
            <p>Select the best date and time for your call below...</p> */}
          </div>

          <div dangerouslySetInnerHTML={{ __html: data?.content }}>
{/* 
            <iframe
              src="https://app.squarespacescheduling.com/schedule.php?owner=27129221"
              title="Schedule Appointment"
              width="100%"
              height="1000"
              frameBorder="0"
            ></iframe>
            <script
              src="https://embed.acuityscheduling.com/js/embed.js"
              type="text/javascript"
            ></script> 
            
            */}
          </div>
        </div>
      </div>
    </div>
  </div>
)
export default Booking
